<template>
  <div class="common-plate">
    <div class="login-out">
      <span class="font-regular" @click="logout">{{
        $trans('退出', '退出')
      }}</span>
    </div>
    <el-divider @click="$router.history.push('/setting')"
      ><img
        class="user-image"
        :src="data.avatar ? data.avatar : '@/assets/img/defaultAvatar.jpg'"
    /></el-divider>
    <div class="user-nickname" @click="$router.history.push('/setting')">
      <span class="font-regular">{{ data.nickName || data.phone }}</span>
    </div>
    <div class="function-container" @click="itemHandle">
      <div
        class="function-item flex-column-cen"
        v-for="(item, index) in functionList"
        :key="index"
        :data-link="item.link"
      >
        <i class="iconfont" :class="item.icon"></i>
        <span class="font-regular">{{ $trans(item.text, item.text) }}</span>
      </div>
    </div>
    <el-dialog
      :title="$trans('客服', '客服')"
      :visible.sync="dialogCustomer"
      width="400px"
    >
      <img src="../../assets/img/kefu.jpg" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { logout, getUserInfo } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      functionList: [
        {
          icon: 'icon-shezhi',
          text: '设置',
          link: '/setting',
        },
        {
          icon: 'icon-wodedingdan',
          text: '我的订单',
          link: '/order',
        },
        {
          icon: 'icon-youhuiquan',
          text: '优惠券',
          link: '/discount',
        },
        {
          icon: 'icon-wodeshoucang',
          text: '我的收藏',
          link: '/collection',
        },
        {
          icon: 'icon-shouhuodizhi3',
          text: '收货地址',
          link: '/address',
        },
        {
          icon: 'icon-zaixiankefu',
          text: '在线客服',
          link: 'dialogCustomer',
        },
      ],
      data: {},
      dialogCustomer: false,
    }
  },
  methods: {
    // 跳转页面
    itemHandle(e) {
      let b = e.path || (e.composedPath && e.composedPath())
      let a = b.filter((item) => {
        if (
          typeof item.className == 'string' &&
          item.className.indexOf('function-item') > -1
        ) {
          return item
        }
      })[0]
      if (a.dataset.link == 'dialogCustomer') {
        this.dialogCustomer = true
        return
      }
      this.$router.history.push(a.dataset.link)
    },
    // 退出
    logout() {
      logout()
        .then((res) => {
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.logoutHandleMethods()
          } else {
            this.logoutHandleMethods()
          }
        })
        .catch(() => {
          this.logoutHandleMethods()
        })
    },
    // 获取用户信息
    getUserInfo() {
      getUserInfo({ userId: this.$store.state.userId }).then((res) => {
        if (res.code == 200) {
          this.data = JSON.parse(JSON.stringify(res.data))
        }
      })
    },
  },
  mounted() {
    this.getUserInfo()
  },
}
</script>
<style scoped>
.common-plate {
  max-width: 1000px;
  width: unset;
}
.login-out {
  text-align: right;
  margin-bottom: 100px;
}
.login-out span {
  cursor: pointer;
  font-size: 16px;
}
.user-image {
  width: 130px;
  height: 130px;
  border-radius: 50%;
  display: block;
  cursor: pointer;
}
.user-nickname {
  text-align: center;
  margin-top: 100px;
  margin-bottom: 100px;
  font-size: 16px;
  cursor: pointer;
}
.function-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 60px;
}
.function-item .iconfont {
  font-size: 28px;
  margin-bottom: 18px;
  font-weight: bold;
}
.function-item {
  font-size: 16px;
  cursor: pointer;
}
</style>
<style>
.el-divider--horizontal {
  height: 2px;
}
.el-dialog__wrapper {
  left: 25%;
}
</style>
